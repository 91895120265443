import axios from "@/api/index";

const baseurl = process.env.VUE_APP_BASE_API;
const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
const api = {
  // 获取党日活动大屏数据
  getActivityDataScreen(params) {
    return axios.get(`${servrt2}/screen/activity`, params);
  },
  //获取思想摸排大屏数据
  getThoughtDataScreen(params) {
    return axios.get(`${servrt2}/screen/thoughtTemplate`, params);
  },
   //获取网上培训大屏数据
   getTrainDataScreen(params) {
    return axios.get(`${servrt2}/screen/webTraining`, params);
  },
  //获取党费缴纳大屏数据
  getPartyPaymentDataScreen(params) {
    return axios.get(`${servrt2}/screen/paymentFee`, params);
  },
  //获取网上考试大屏数据
  getExamOnlineDataScreen(params) {
    return axios.get(`${servrt2}/screen/onlineExamination`, params);
  },
  //获取民主评议大屏数据
  getDemocraticDataScreen(params) {
    return axios.get(`${servrt2}/screen/democraticAppraisal`, params);
  },
  //获取民主评议大屏数据
  getStatisticsDataScreen(params) {
    return axios.get(`${servrt1}/statistics/screenProportion`, params);
  },
   //获取责任清单大屏数据
   getMissionDataScreen(params) {
    return axios.get(`${servrt2}/screen/task`, params);
  },
  //获取线下会议大屏数据
  getMeetingDataScreen(params) {
    return axios.get(`${servrt2}/screen/meeting`, params);
  },
  //获取党日活动总数
  getActivityDataScreenTotal(params) {
    return axios.get(`${servrt2}/screen/activityCount`, params);
  },
   //获取责任清单总数
   getMissionDataScreenTotal(params) {
    return axios.get(`${servrt2}/screen/taskCount`, params);
  },
   //获取线下会议总数
   getmeetingDataScreenTotal(params) {
    return axios.get(`${servrt2}/screen/meetingCount`, params);
  },
  //数据大屏查询人员信息
  getDataScreenPersonTotal(params) {
    return axios.get(`${servrt1}/user/queryUser`, params);
  },
};
export default api;
