import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const api = {
    // 获取个人收藏
    collectList(params) {
        return axios.get(`${servrt1}/collect/page`, params);
    },
     // 获取下载记录
     downloadRecordt(params) {
        return axios.get(`${servrt1}/collect/page`, params);
    },
}

export default api