import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const api = {
  // 新闻详情
  newDetails(params) {
    return axios.get(`${servrt1}/home/journalism/${params}`);
  },
  //详情
  allDetails(params) {
    return axios.get(`${servrt1}/home/comprehensive/${params}`);
  },

  //下载附件列表
  download(params) {
    return axios.get(`${servrt1}/attachment/`, params);
  },
  //附件下载
  downloadFliter(params) {
    return axios.get(`${servrt1}/attachment/download/${params}`);
  },
  //附件下载url
  downloadUrl(params) {
    return `${servrt1}/attachment/download/${params}`
  },
  //查询收藏
  InquireCollect(params) {
    return axios.get(`${servrt1}/collect`,params);
  },
  //收藏
  collect(params) {
    return axios.post(`${servrt1}/collect`,params);
  },
  //取消收藏
  delCollect(params) {
    return axios.delete(`${servrt1}/collect/${params}`);
  }
}
export default api