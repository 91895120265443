import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;
const server3 = baseurl+'/'+process.env.VUE_APP_SERVER3;
const api = {
    // 获取心愿的列表
    getWishList(data) {
        return axios.get(`${server3}/wish/getWishListAndPage`, data);
    },
    // 获取心愿类型的列表
    getWishTypeList(data) {
        return axios.get(`${server3}/wish/getWishTypeList`, data);
    },
    // app心愿认领
    getWishClaim(data) {
        return axios.put(`${server3}/wish/wishClaim?id=`+ data);
    },
    // app心愿认领详情
    getWishDetailsById(data) {
        return axios.get(`${server3}/wish/getWishDetail`, data);
    },
    // app心愿打卡
    getWishClock(data) {
        return axios.post(`${server3}/wish/wishClock`, data);
    },
    //获取该心愿的打卡列表
    getWishClockCardList(data) {
        return axios.get(`${server3}/wish/getWishClockList`, data);
    },
    //完成心愿
    finishWish(data) {
        return axios.put(`${server3}/wish/endWish`, data);
    },
};
export default api;
