// 党员画像
export default [
    {
      path: "portrait",
      name: "portrait",
      component: () => import("@/views/portrait/index.vue"),
    },
    {
      path: "chart",
      name: "chart",
      component: () => import("@/views/portrait/chart.vue"),
    },
  ];