import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;
const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
// const servrt3 = baseurl+'/'+ process.env.VUE_APP_SERVER3;
const api = {
    // 获取积分
    getScore(params){
        return axios.get(`${servrt1}/user/score/userRadarMap`, params);
    },
    // 获取个人学习教育和组织生活的相关信息
    getMyData(){
        return axios.get(`${servrt1}/portrait/myData`); 
    },
    // 获取积分领域列表
    getCategoryList(params) {
        return axios.get(`${servrt1}/user/score/categoryList` ,params);
    },
    //个人收藏、下载次数
    getUserStudyCount(params){
        return axios.get(`${servrt1}/collect/userStudyCount`, params)
    },
    // 党费缴纳列表
    getPayable(params) {
        return axios.get(`${servrt2}/party/userPartyFeeNums`, params);
    },
    // 获取个人某时间段内的考试次数
	getUserExamCount(params) {
		return axios.get(`${servrt2}/exam/paper/userExamCount`, params);
	},
    //个人某年内的培训完成次数
	getUserTrainCount(params) {
		return axios.get(`${servrt2}/admintrain/userTrainCount`, params);
	},
    //获取个人某段时间的活动次数
	getUserActivityCount(params) {
		return axios.get(`${servrt2}/adminactivity/userActivityCount`,params);
	},

     //个人某年的考核次数
    getUserEvaluationCount(params) {
        return axios.get(`${servrt2}/evaluation/userEvaluationCount`,params);
    },
    // 获取当前用户所参加的会议数量
	getUserMeetingCount(params) {
		return axios.get(`${servrt2}/adminmeeting/userMeetingCount`,params);
	},
    //获取个人某年所参与的调查问卷数量
	getUserInvestigateCount(params) {
		return axios.get(`${servrt2}/investigate/userInvestigateCount`, params);
	},
    //获取个人某年所参与的民主评议数量
    getUserAppraiseCount(params) {
        return axios.get(`${servrt2}/appraise/app/userAppraiseCount`,params);
    },
    



    //  //获取个人时间段内完成的考试
	// getUserExamList(params) {
	// 	return axios.get(`${servrt2}/exam/paper/userExamList`, params);
	// },
    // //个人某年内的培训完成列表
    // getUserTrainList(params) {
    //     return axios.get(`${servrt2}/admintrain/userMeetingList`,params);
	// },
    // //获取个人某段时间的活动完成列表
	// getUserActivityList(params) {
	// 	return axios.get(`${servrt2}/adminactivity/userActivityList`,params);
	// },
    // //个人学习列表
    // getUserStudyList(params){
    //     return axios.get(`${servrt1}/collect/userStudyList`, params)
    // },
    // //个人某年的考核列表
    // getUserEvaluationList(params) {
    //     return axios.get(`${servrt2}/evaluation/userEvaluationList`,params);
    // },
    // // 获取当前用户所参加的会议列表
	// getUserMeetingList(params) {
	// 	return axios.get(`${servrt2}/adminmeeting/userMeetingList`,params);
	// },
    // //获取个人某年所参与的调查问卷列表
	// getUserInvestigateList(params) {
	// 	return axios.get(`${servrt3}/investigate/userInvestigateList`, params);
	// },
    // // 个人获取某年已完成的民主评议列表
    // getUserAppraiseList(params) {
    //     return axios.get(`${servrt2}/appraise/app/userAppraiseList`,params);
    // }

  

}

export default api