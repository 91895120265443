// 思想摸排
export default [
  {
    path: "thought",
    name: "thought",
    component: () => import("@/views/thought/index.vue"),
  },
  {
    path: "thoughtDetail",
    name: "thoughtDetail",
    component: () => import("@/views/thought/detail.vue"),
  },
];
