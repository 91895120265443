import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;
const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2+'/new';
const api = {
    // 我的投票列表
    getVoteList(params) {
        return axios.get(`${servrt2}/vote/page`, params);
    },
    // 查询选项详情
    getOptionDetail(params) {
        return axios.get(`${servrt2}/vote/option/detail/${params}`);
    },
    // 活动是否开始
    startVote(params) {
        return axios.get(`${servrt2}/vote/isStart/${params}`);
    },
     // 根据id查看活动详情
     getVoteDetail(params) {
        return axios.get(`${servrt2}/vote/detail/${params}`);
    },
    // 查看排行
    getVoteRank(params) {
        return axios.get(`${servrt2}/vote/rank/${params}`);
    },
    // 投票
    clickVote(params) {
        return axios.put(`${servrt2}/vote/vote`,params);
    },
    // 搜索
    // seachVote(params,activityId) {
    //     return axios.get(`${servrt2}/vote/rank/${activityId}`,params);
    // },
    // 我的投票信息
    voteuserInfo(params) {
        return axios.get(`${servrt2}/vote/userInfo/${params}`);
    },
    // 查看选项列表
    lookOptionList(params,activityId) {
        return axios.get(`${servrt2}/vote/option/list/${activityId}`,params);
    },
    // 访问量
    editVisitNum(activityId) {
        return axios.put(`${servrt2}/voteActivity/visitNum?activityId=${activityId}`);
    },
}

export default api;