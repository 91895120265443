//用户信息
const state = {
  isback:-1,
    column:[
      // {
      //   columnName: "首页",
      //   apiurl: "/",
      //   isleaf: true,
      //   flag:true,   //是否显示
      //   children: []
      // },
      // {
      //   keyword:"organization",
      //   columnName: "组织架构",
      //   isleaf: false,
      //   apiurl: "/organization",
      //   flag:true,   //是否显示
      //   children: []
      // },

      {
        columnName: "党建学习",
        isleaf: true,
        apiurl: "",
        keyAssignments:'1',
        flag:true,   //是否显示
        children: [
          {
            keyword:'learn',
            columnName: "两学一做",
            children: [],
            keyAssignments:'1',
            isleaf: false,
            apiurl: "learn",
            flag:true,   //是否显示
            param: { 
              moduleId:3, 
              pageIndex:1,
              pageSize:10
            },
          },
          {
            keyword:'lesson',
            columnName: "三会一课",
            keyAssignments:'2',
            children: [],
            isleaf: false,
            apiurl: "lessonList",
            flag:true,   //是否显示
            param: {
              moduleId:4,
              pageIndex:1,
              pageSize:10
              },
          },
        ],
      },
      {
        columnName: "信息咨询",
        isleaf: true,
        keyAssignments:'2',
        apiurl: "",
        flag:true,   //是否显示
        children: [
          {
            keyword:'punishment',
            columnName: "表彰与处分",
            keyAssignments:'1',
            children: [],
            isleaf: false,
            apiurl: "punishment",
            flag:true,   //是否显示
            param: {
              moduleId:1,
              pageIndex:1,
              pageSize:10},
          },
          {
            keyword:'column',
            columnName: "党建动态",
            keyAssignments:'2',
            children: [],
            isleaf: true,
            apiurl: "columnList",
            flag:true,   //是否显示
            param: {}
          },
          
        ],
      },
      {
        columnName: "党建工作",
        isleaf: true,
        apiurl: "",
        keyAssignments:'3',
        flag:true,   //是否显示
        children: [
          {
            keyword:'rules',
            columnName: "规章制度",
            keyAssignments:'1',
            children: [],
            isleaf: false,
            apiurl: "system",
            flag:true,   //是否显示
            param: {
              moduleId:2,
              pageIndex:1,
              pageSize:10
            },
          },
          {
            keyword:'download',
            columnName: "资料下载",
            keyAssignments:'2',
            children: [],
            isleaf: false,
            apiurl: "lessonList",
            flag:true,   //是否显示
            param: { moduleId:6,
               pageIndex:1,
               pageSize:10 },
          },
          {
            keyword:'support',
            columnName: "困难帮扶",
            keyAssignments:'3',
            children: [],
            isleaf: false,
            apiurl: "punishment",
            flag:true,   //是否显示
            param: {
               moduleId:5,
               pageIndex:1,
               pageSize:10
            },
          },
        ],
      },
    ],
    show:'',
    meunList:[
      {
        name: "待办任务",
        router: "/personal/TodoMessageIndex",
        icon: "integral",
        keepName: "TodoMessage",
        keyword:"Notice",
        flag:true
      },
      {
        name: "个人信息",
        keyword:'information',
        router: "/personal/information",
        icon: `person`,
        keepName: "Information",
        flag:true
      },
      {
        name: "责任清单",
        keyword:'mission',
        router: "/personal/mission",
        icon: "questionnaire",
        keepName: "Mission",
        flag:true
      },
      {
        name: "我的数据",
        keyword:'dataAnalysis',
        router: "/personal/DataAnalysis",
        icon: `avatar`,
        keepName: "DataAnalysis",
        flag:true, 
        index:2
      },
      {
        id:32,
        name: "年度考核",
        keyword:'annualCheck',
        router: "/personal/AnnualCheck",
        icon: `thought`,
        keepName: "AnnualCheck",
        flag:true,
        index:3 
      },
      {
        name: "工作计划/总结",
        keyword:'workPlan',
        router: "/personal/workPlan",
        icon: "gongzuos",
        keepName: "WorkPlan",
        flag:true
      },
      {
        name: "网上考试",
        keyword:'exam',
        router: "/personal/ExamIndex",
        icon: "gongzuo",
        keepName: "ExamIndex",
        flag:true
      },
      {
        id:2,
        name: "通知公告",
        keyword:'notice',
        router: "/personal/notice",
        icon: "daiban",
        keepName: "Notice",
        flag:true
      },
      {
        name: "调查问卷",
        keyword:'questionnaire',
        router: "/personal/questionnaire",
        icon: "questionnaire",
        keepName: "questionnaire",
        flag:true
      },
      {
        name: "难事上报",
        keyword:'problemReporting',
        router: "/personal/problemReporting",
        icon: "investigate",
        keepName: "ProblemIndex",
        flag:true
      },
      {
        name: "思想摸排",
        keyword:'thought',
        router: "/personal/thought",
        icon: "thought",
        keepName: "Thought",
        flag:true
      },
      {
        name: "线下会议",
        keyword:'meeting',
        router: "/personal/meeting",
        icon: "meeting",
        keepName: "Meeting",
        flag:true
      },
      {
        name: "党校培训",
        keyword:'train',
        router: "/personal/train",
        icon: "train",
        keepName: "Train",
        flag:true
      },
      {
        name: "党日活动",
        keyword:'activity',
        router: "/personal/activity",
        icon: "activity",
        keepName: "Activity",
        flag:true
      },
      {
        name: "心愿打卡",
        keyword:'wishCardIndex',
        router: "/personal/wishCardIndex",
        icon: "wishCard",
        keepName: "WishCardIndex",
        flag:true
      },
      
      {
        name: "学习记录",
        keyword:'learning',
        router: "/personal/learning",
        icon: "learning",
        keepName: "Learning",
        flag:true
      },

  
      {
        name: "书架",
        keyword:'bookCase',
        router: "/personal/bookCase",
        icon: "questionnaire",
        keepName: "BookCase",
        flag:true
      },
      {
        name: "投票活动",
        keyword:'investigate',
        router: "/personal/investigate",
        icon: "investigate",
        keepName: "Investigate",
        flag:true
      },
      {
        name: "书记信箱",
        keyword:'email',
        router: "/personal/email",
        icon: "email",
        keepName: "Email",
        flag:true
      },
      {
        name: "民主评议",
        keyword:'democracy',
        router: "/personal/democracy",
        icon: "democracy",
        keepName: "Democracy",
        flag:true
      },
      {
        name: "党费缴纳",
        keyword:'payMoney',
        router: "/personal/payMoney",
        icon: "meeting",
        keepName: "Paymoney",
        flag:true
      },

      {
        name: "志愿服务",
        keyword:'helpInPairs',
        router: "/personal/helpInPairs",
        icon: "helpInPairs",
        keepName: "Index",
        flag:true
      },
      {
        name: "在线学习",
        keyword:'OnlineStudy',
        router: "/personal/onlineStudy",
        icon: "questionnaire",
        keepName: "OnlineStudy",
        flag:true
      },


      {
        name: "场地预约",
        keyword:'field',
        router: "/personal/FieldIndex",
        icon: "meeting",
        keepName: "FieldIndex",
        flag:true
      },
      {
        name: "积分中心",
        keyword:'integral',
        router: "/personal/integral",
        icon: "integral",
        keepName: "Integral",
        flag:true
      },
      {
        name: "党员信息维护",
        keyword:'baseUserIndex',
        adminType:1,
        flag:false
      }
    ]
  };
  const mutations = {
    isback(state, payload) {
      state.isback = payload
    },
    updateInfo(state, payload) {
        state.column = payload
    },
    updateName(state, payload) {
      state.urlName = payload
    },

    //更新显示模块
    updateMeunList(state, payload) {

      // 是否显示
      let show=true;
      // 修改column中的children
      function setChildren(data,keyword,flag){
        data.some(item=>{
          if(item.keyword==keyword){
            item.flag=flag
            return item.keyword==keyword
          }
        })
        // 判断所有子模块是否全部不显示
        show=data.some(item=>{
          return item.flag
        })

        return show       
      }

      function traverse(){
        for(const item of payload){
          if(item.pc!=undefined){

            state.column.some(col=>{
              if(col.keyword!=undefined){
                if(item.keyword==col.keyword){
                  col.flag=item.pc==1 ? true : false
                }
              }else{
                col.flag = setChildren(col.children,item.keyword,item.pc) ;
              }
              return col.keyword==item.keyword
            })


            state.meunList.some(meu=>{
              if( item.keyword == meu.keyword ){
                meu.flag=item.pc==1 ? true : false
              }
              return item.keyword == meu.keyword
            })
          
            // if(item.pc==0){
            //   // 遍历column修改隐藏
            //   state.column.some(col=>{
            //     if(col.keyword!=undefined){
            //       if(item.keyword==col.keyword){
            //         col.flag=false
            //       }
            //     }else{
            //       col.flag =col.children.length!=0 ?  !setChildren(col.children,item.keyword) : false;
            //     }
           
            //     return col.keyword==item.keyword
            //   })
              // for(const col of state.column){

              //   if(col.flag==false){
              //     continue
              //   }
              //   col.flag =!setChildren(col.children,item.keyword);     // 检测下级是否全部隐藏。全部隐藏后返回true,下级全部隐藏后赋值为false
              // }
              // 遍历column修改隐藏
            //   for(const meu of state.meunList){
            //     if(meu.flag==false){
            //       continue
            //     }
            //     if( item.keyword == meu.keyword ){
            //       meu.flag=false
            //     } 
            //   }
            // }

          }else{
            state.meunList.some((e)=>{
              if(e.keyword==item.keyword){
                if(e.adminType){
                  e.adminType=item.admin
                }else{
                  e.flag=(item.pc==1 ? true :false)
                }
              }
              return e.keyword==item.keyword
            })
          }
        }
      }
      traverse()
    },
  };
  const actions = {
    setlist(conent, value) {
      conent.commit("updateInfo", value);
    },
    setname(conent, value) {
      conent.commit("updateName", value);
    },

    setMeunList(conent, value) {
      console.log("进入仓库")
      conent.commit("updateMeunList", value);
    },
    isback(conent, value) {
      conent.commit("isback", value);
    },
  };
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };
  