import axios from "@/api/index";

const baseurl = process.env.VUE_APP_BASE_API;
const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
const api = {
  // 获取组织树(推送)
  dataAnalysisTree(params) {
    return axios.get(`${servrt1}/org/tableTree/push`, params);
  },
  // 我的会议-考核-党费数据
  dataAnalysisMyData(params) {
    return axios.get(`${servrt1}/statistics/myData`, params);
  },
  // 组织数据(会议和考试)
  dataAnalysisOrgData(params) {
    return axios.get(`${servrt1}/statistics/orgData`, params);
  },

   // 按年份分析:党员情况分析
   getDataAnalysisYear(params) {
    return axios.get(`${servrt1}/statistics/year`, params);
  },
  // 党员性别分析
  getDataAnalysisGender(params) {
    return axios.get(`${servrt1}/statistics/gender`, params);
  },
  // 按学历分析
  getDataAnalysisEducation(params) {
    return axios.get(`${servrt1}/statistics/education`, params);
  },
  // 党员民族占比情况分析
  getDataAnalysisNation(params) {
    return axios.get(`${servrt1}/statistics/nation`, params);
  },
  // 获取组织树
  getDataAnalysisOrgTree(params) {
    return axios.get(`${servrt1}/org/tableTree/push`, params);
  },
  // 查询民族列表
  getDataAnalysisNationList(params) {
    return axios.get(`${servrt1}/nation`, params);
  },
  // 查询学历列表
  getDataAnalysisEducationList(params) {
    return axios.get(`${servrt1}/education`, params);
  },
  // 分页查询用户
  getDataAnalysisUser(params) {
    return axios.get(`${servrt1}/statistics/user`, params);
  },
  // 搜索
  getDataAnalysisSearch(params) {
    return axios.get(`${servrt1}/statistics/search`, params);
  },
  // 党费缴纳情况分析
  // getDataAnalysisYear(params) {
  //   return axios.get(`${servrt1}/statistics/year`, params);
  // },
  // 会议开展情况分析
  // getDataAnalysisYear(params) {
  //   return axios.get(`${servrt1}/statistics/year`, params);
  // },
  // 查看会议本年的列表
  getDataAnalysisSearchYearMeetingList(params) {
    console.log('params: ', params);
    return axios.get(`${servrt2}/adminmeeting/getYearMeetingList`, params);
  },
  // 查看会议本年的次数和每月的次数
  getDataAnalysisMeeting(params) {
    return axios.get(`${servrt2}/adminmeeting/getMeetingCount`, params);
  },
  // 查看活动本年的列表
  getDataAnalysisSearchYearActivityList(params) {
    console.log('params: ', params);
    return axios.get(`${servrt2}/adminactivity/getYearMeetingList`, params);
  },
  // 查看活动本年的次数和每月的次数
  getDataAnalysisActivity(params) {
    return axios.get(`${servrt2}/adminactivity/getPartyActivityCount`, params);
  },
  // 查看调查问卷本年的列表
  getDataAnalysisSearchYearQuestionList(params) {
    console.log('params: ', params);
    return axios.get(`${servrt2}/investigate/getInvestigateList`, params);
  },
  // 查看调查问卷本年的次数和每月的次数
  getDataAnalysisQuestion(params) {
    return axios.get(`${servrt2}/investigate/getInvestigateCount`, params);
  },
  // 查看思想摸排本年的列表
  getDataAnalysisSearchYearTalkList(params) {
    console.log('params: ', params);
    return axios.get(`${servrt2}/heartTalk/getHeartTalkYearList`, params);
  },
  // 查看思想摸排本年的次数和每月的次数
  getDataAnalysisTalk(params) {
    return axios.get(`${servrt2}/heartTalk/getHeartTalkCount`, params);
  },
  // 查看网上考试本年的列表
  getDataAnalysisSearchYearExamList(params) {
    console.log('params: ', params);
    return axios.get(`${servrt2}/exam/paper/getYearExamList`, params);
  },
  // 查看网上考试本年的次数和每月的次数
  getDataAnalysisExam(params) {
    return axios.get(`${servrt2}/exam/paper/getExamCount`, params);
  },
  // 查看党费缴纳本年的次数和每月的次数
  getDataAnalysisParty(params) {
    return axios.get(`${servrt2}/party/getPartyFeeCount`, params);
  },
  // 获取组织详情
  getOrganizeDetail(params) {
    return axios.get(`${servrt1}/org/getDetails`, params);
  },
  // 获取组织下的人员
  getOrganizeUserPage(params) {
    return axios.get(`${servrt1}/user/page`, params);
  }
};
export default api;
