//举例测试数据
const state = {
  // xss攻击白名单
  xssOptions: {
    whiteList: {
      span: ["class"],
      p: ["class"],
      a: ["href", "title", "target"],
    },
  },
};
const getters = {};
const mutations = {};
const actions = {};
export default {
  state,
  getters,
  mutations,
  actions,
};
