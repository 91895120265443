// 个人信息模块
export default [
    // 首页
    {
      path: "information",
      name: "information",
      component: () => import("@/views/information/index.vue"),
    },
    {
      path: "informationEdit",
      name: "informationEdit",
      component: () => import("@/views/information/edit.vue"),
    },
  ];