import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
const api = {
     // 学习园地栏目列表
     learnList(params) {
        return axios.get(`${servrt2}/studyOnlineColumn/columnList`, params);
    },
}

export default api