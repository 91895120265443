// 微心愿
export default [
    // 首页
    {
      path: "wishCardIndex",
      name: "wishCardIndex",
      component: () => import("@/views/wishCard/index.vue"),
    },
    {
      path: "wishCardDetail",
      name: "wishCardDetail",
      component: () => import("@/views/wishCard/detail.vue"),
    },
    {
      path: "wishCardWishCard",
      name: "wishCardWishCard",
      component: () => import("@/views/wishCard/wishCard.vue"),
    },
    
  ];
  