import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
const api = {
  
  //人员信息
  peopleDetail(params) {
    return axios.get(`${servrt1}/user/${params}`);
  },

  //我的评议列表
  mydemocracy(params) {    
    return axios.get(`${servrt2}/appraise/app/page`, params);
  },
  //我的评议历史记录列表
  myhisdemocracy(params) {
    return axios.get(`${servrt2}/appraise/app/page/historyRecords`, params);
  },
  //书记评议列表
  secretaryDemocracy(params) {
    return axios.get(`${servrt2}/appraise/app/page/secretary`, params);
  },
  //书记评议历史列表
  secretaryHisDemocracy(params) {
    return axios.get(`${servrt2}/appraise/app/page/secretaryHistory`, params);
  },
   //is书记
   isuser(params) {
    return axios.get(`${servrt2}/appraise/app/isSecretary`, params);
  },
   //评议人列表
   peoplelist(params) {
    return axios.get(`${servrt2}/appraise/app/page2`, params);
  },
   //题列表
   questionDemocracy(params) {
    return axios.get(`${servrt2}/appraise/list/${params}`);
  },
  //自评
  selfProcess(params) {
    return axios.put(`${servrt2}/appraise/app/submitResults`,params);
  },
  //
  //互评
  peerProcess(params) {
    return axios.put(`${servrt2}/appraise/app/eachOther`,params);
  },
  //书记互评
  secretaryProcess(params) {
    return axios.put(`${servrt2}/appraise/app/secretarySubmitResults`,params);
  },

  //人员信息
  selfpeopleDetail(params) {
    return axios.get(`${servrt2}/appraise/app/pageSelf`,params);
  },
   //非书记历史详情
   hispeopleDetail(params) {
    return axios.get(`${servrt2}/appraise/app/page/historyRecordsDetail`,params);
  },
}
export default api