import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import store from "./store";
import ElementPlus from "element-plus";
import zhCn from 'element-plus/es/locale/lang/zh-cn'

//  import "element-plus/dist/index.css"; //element css
import "@/styles/element-ui.scss";
// import "@/styles/element.scss"; //element css
// import "@/styles/index.scss"; // global css


import "@/assets/icon/iconfont.css"; 
const app = createApp(App);

app.use(store).use(router).use(ElementPlus,{
  locale: zhCn,
}).mount("#app");
import { api } from "./api/list";
import { tipsText,btnText } from "@/hooks/tipsText";
import debounce from "@/instruct/debounce";
debounce(app)
app.config.globalProperties.$api = api;
app.config.globalProperties.$tipsText = tipsText;
app.config.globalProperties.$btnText = btnText;
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $api: typeof api;
    $tipsText: typeof tipsText;
    $btnText: typeof btnText;
  }
}
