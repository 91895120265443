import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt3 = baseurl + "/" + process.env.VUE_APP_SERVER3;

const api={
	// 获取当前人员身份
	getPersonIdentity(){
		return axios.get(`${servrt3}/appHelpObject/personIdentity`)
	},
	//获取结对帮扶列表
	getHelpInPairsList(data){
		return axios.get(`${servrt3}/appHelpObject/help?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}&type=${data.type}&userName=${data.userName}`);
	},
	//获取帮扶详情
	getHelpInPairsDetail(data){
		return axios.get(`${servrt3}/appHelpObject/selectDetail`, data);
	},
	
	// 被帮扶人获取列表信息
	getbeHelpList(data){
		return axios.get(`${servrt3}/beHelp/beHelpList`, data);
	},
	
	//修改帮扶状态
	getHelpInPairsChange(data){
		return axios.get(`${servrt3}/appHelpObject/editStatus`, data)
	},
	//添加进度
	addHelpInPairsProcess(data){
		return axios.post(`${servrt3}/appHelpObject`, data)
	},
	//被帮扶人修改状态
	editState(data){
		return axios.get(`${servrt3}/beHelp/editState?objectId=${data.objectId}&state=${data.state}`)
	}
}
export default api;



