//用户信息
const state = {
  otherData:{},
  examTime:0,
};
const getters = {
  getOtherData: () => {
    return state.otherData;
  },
  getExamTime: () => {
    return state.examTime;
  },
};
const mutations = {
  otherDataChange(state, value) {
    state.otherData=value
  },
  examTimeChange(state, value) {
    console.log('value: ', value);
    state.examTime=value
  },
};
const actions = {
  
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
