export default [
    {
      path: "meeting",
      name: "meeting",
      component: () => import("@/views/meeting/index.vue"),
    },
    {
      path: "meetingSignUp",
      name: "meetingSignUp",
      component: () => import("@/views/meeting/signUp.vue"),
    },
    {
      path: "meetingDetail",
      name: "meetingDetail",
      component: () => import("@/views/meeting/detail.vue"),
    },
    {
      path: "meetingAttendance",
      name: "meetingAttendance",
      component: () => import("@/views/meeting/attendance.vue"),
    },
]