// 通知公告
export default [
  {
    path: "notice",
    name: "notice",
    component: () => import("@/views/notice/index.vue"),
  },
  {
    path: "noticeDetail",
    name: "noticeDetail",
    component: () => import("@/views/notice/detail.vue"),
  },
];
