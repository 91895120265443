export default [
    {
      path: "train",
      name: "train",
      component: () => import("@/views/train/index.vue"),
    },
    {
      path: "trainSignUp",
      name: "trainSignUp",
      component: () => import("@/views/train/signUp.vue"),
    },
    {
      path: "trainDetail",
      name: "trainDetail",
      component: () => import("@/views/train/detail.vue"),
    },
    {
      path: "trainAttendance",
      name: "trainAttendance",
      component: () => import("@/views/train/attendance.vue"),
    },
]