export const tipsText = {
    $tips_del: "删除后将不能恢复，是否继续?",
    $tips_success: "操作成功",
    $tips_error: "操作失败",
    $tips_cancle: "是否确定撤销",
    $tips_addsuccess: "新增成功",
    $tips_editsuccess: "编辑成功",
    $tips_tableloading: "拼命加载中",
    $my_record:"我发起的",

    // 区分是否是从责任清单中发起的
    $tips_source:"任务"
  };
  export const btnText = {
    $btn_submit: "提交",
  };
  
  