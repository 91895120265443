//用户信息
const state = {
  user:JSON.parse(sessionStorage.getItem(`user`) as string )||{name:1},
};
const getters = {
  getUser: () => {
    return state.user;
  },
};
const mutations = {
  SET_USER(state, value) {
    sessionStorage.setItem("user", JSON.stringify(value));
    state.user=value
  },
};
const actions = {
  setuser({ commit }, value) {
    commit("SET_USER", value);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
