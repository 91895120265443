import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt2 = baseurl + "/" + process.env.VUE_APP_SERVER2;
const api = {
  // pc网上考试列表
  IntegratedExamHistoryMylist(params) {
    return axios.get(`${servrt2}/exam/history/mylist`, params);
  },
  // 判断是否到考试时间
  IntegratedExamHistoryIsStart(params) {
    return axios.get(`${servrt2}/exam/history/isStart`, params);
  },
  // 获取要考试的试题详情
  IntegratedExamHistoryGetExamList(params) {
    return axios.get(
      `${servrt2}/exam/history/getExamList/${params.historyId}`,
      {}
    );
  },
  // 获取考试记录详情
  IntegratedExamHistory(params) {
    return axios.get(`${servrt2}/exam/history/${params.historyId}`, {});
  },
  // 提交考试结果:交卷
  IntegratedExamHistorySubmitResult(params) {
    return axios.post(`${servrt2}/exam/history/submitResult`, params);
  },
  // 考完的试题列表详情:已考试列表
  IntegratedExamHistorDetailList(params) {
    return axios.get(
      `${servrt2}/exam/history/getExamDetailList/${params.historyId}`,
      {}
    );
  },
  // 考试记录的详情:交卷之后获取成绩
  IntegratedExamHistorDetail(params) {
    return axios.get(`${servrt2}/exam/history/${params.historyId}`, {});
  },
  // 新增考试心得
  IntegratedExamHistorAddHeart(params) {
    return axios.put(`${servrt2}/exam/history/addtip`, params);
  },

  //推送给我的
  examworkselectList(params) {
    return axios.get(`${servrt2}/workSummaryRecord/pcList`, params);
  },
  //私人的工作计划列表
  examworkselectSelfList(params) {
    return axios.get(`${servrt2}/workSummaryRecord/pcSelfList`, params);
  },
  // 查询列表：分页查询
  ExamSelectPage(params) {
    return axios.get(`${servrt2}/exam/paper/selectPage`, params);
  },

  // 获取考试排行榜
  rankingone(params) {
    return axios.get(`${servrt2}/exam/history`,params);
  }
};
export default api;
