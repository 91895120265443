// 在线学习
export default [
    {
      path: "onlineStudy",
      name: "OnlineStudy",
      component: () => import("@/views/onlineStudy/index.vue"),
    },
    {
      path: "studyCollection",
      name: "StudyCollection",
      component: () => import("@/views/onlineStudy/collection.vue"),
    },
    {
      path: "studyRank",
      name: "StudyRank",
      component: () => import("@/views/onlineStudy/ranking.vue"),
    },
    {
      path: "studyDetail",
      name: "StudyDetail",
      component: () => import("@/views/onlineStudy/detail.vue"),
    },
  ];