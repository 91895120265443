
export default function (app) {
  app.directive('debounce', {
    mounted (el,binding) {
      el.addEventListener("click", () => {
        if (!el.disabled) {
          el.disabled = true;
          setTimeout(() => {
            el.disabled = false;
          }, binding.value || 1500);
        }
      });
    }
  })

}


