import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;

const api = {
    // 附件获取
    getUploadInfo(params) {
        return axios.get(`${servrt1}/attachment/`, params);
    },

    // 删除附件
    delTalkAnnex(attachmentId) {
        return axios.delete(`${servrt1}/attachment/${attachmentId}`);
    },
    // 我的谈话列表
    getThoughtList(params) {
        return axios.get(`${servrt2}/appTalk/talk`, params);
    },

    // 我的审核列表
    getAuditList(params) {
        return axios.get(`${servrt2}/appTalk/approve`, params);
    },

    // 审批
    turnAudit(params) {
        return axios.put(`${servrt2}/appTalk`, params);
    },

    // 详情
    getTalkDetail(heartId) {
        return axios.get(`${servrt2}/heartTalk/${heartId}`);
    },

    // 谈话上传附件
    uploadTalkAnnex(params) {
        return axios.put(`${servrt2}/heartTalk/talkAttachment`, params);
    },


}

export default api