import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
const api = {
    // 党费缴纳列表
    getPayables(params) {
        return axios.get(`${servrt2}/appPartyfee/payable`, params);
    },

    // 总费用
    getPayableMoney(params) {
        return axios.get(`${servrt2}/appPartyfee/payableMoney`,params);
    }
    
}

export default api