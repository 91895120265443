import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
const api = {
    // 我的谈话列表
    getQuestList(params) {
        //return axios.get(`${servrt2}/investigate/list`, params);
        return axios.get(`${servrt2}/pcInvestigate/myList`, params);
    },
    // 获取试题
    getQuestion(params) {
        //return axios.get(`${servrt2}/investigate/getQuestion`, params);
        return axios.get(`${servrt2}/pcInvestigate/getQuestion`, params);
    },
    // 提交试题
    submitQuestion(params) {
        return axios.post(`${servrt2}/pcInvestigate/submitResult`, params);
    },
    // 提交试题
    timeQuestion(params) {
        return axios.get(`${servrt2}/pcInvestigate/isStart`, params);
    },
    // 提交试题
    getAnswer(params) {
        return axios.get(`${servrt2}/pcInvestigate/getQuestionDetail`, params);
    },
}

export default api