export default [
    {
      path: "activity",
      name: "activity",
      component: () => import("@/views/activity/index.vue"),
    },
    {
      path: "activitySignUp",
      name: "activitySignUp",
      component: () => import("@/views/activity/signUp.vue"),
    },
    {
      path: "activityDetail",
      name: "activityDetail",
      component: () => import("@/views/activity/detail.vue"),
    },
    {
      path: "activityAttendance",
      name: "activityAttendance",
      component: () => import("@/views/activity/attendance.vue"),
    },
]