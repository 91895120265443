import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;
const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const api = {
    // 积分列表
    getIntegralList(params) {
        return axios.get(`${servrt1}/user/score/myList`, params);
    },
    // 个人积分值
    getmyScore(params) {
        return axios.get(`${servrt1}/user/score/myScore`,params);
    },
    // 新增积分
    addmyScore(params) {
        return axios.post(`${servrt1}/user/score`,params);
    },
    // 今日积分
    scoreToday(params) {
        return axios.get(`${servrt1}/user/score/today`,params);
    },
    // 分数排行
    scoreRank(params) {
        return axios.get(`${servrt1}/user/score/rank`,params);
    },
}

export default api;