import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;
const servrt2 = baseurl + "/" + process.env.VUE_APP_SERVER2;
const api = {
  // 不分页获取书籍分类列表
  getBookClassificationNoPage(params) {
    return axios.get(`${servrt2}/books/type/typelistnot`, params);
  },
  // 获取书籍列表
  getAllBookbyPage(params) {
    return axios.get(`${servrt2}/bookrack/pclistbooks`, params);
  },
  // 查看图书
  getAllBookbyDetail(params) {
    return axios.get(`${servrt2}/books/book`, params);
  },
  // 加入书架
  getBookJoinBookcase(params) {
    return axios.post(`${servrt2}/bookrack/user/addmylist?bookId=`+ params);
  },
  // 我的书架
  getBookMyBookcase(params) {
    return axios.get(`${servrt2}/bookrack/user/mybookracklist`, params);
  },
  //删除我收藏的图书
  getMyBookcaseDel(params) {
    return axios.delete(`${servrt2}/bookrack/user/deletemylist?bookIds=`+ params);
  },
  // 获取书籍目录
  getAllBookbychapter(params) {
    return axios.get(`${servrt2}/books/chapter/catalog`, params);
  },
  //查看已读截止章节
  getBookReadCatalog(params) {
    return axios.get(`${servrt2}/books/user/personrecord`, params);
  },
  //存储阅读信息
  getBookSaveCatalog(params) {
    return axios.put(`${servrt2}/books/user/storerecord`, params);
  },
  //根据章节id获取本章内容
  getBookcontentByCatalog(params) {
    return axios.get(`${servrt2}/books/chapter/reading`, params);
  },
  //查询图书是否收藏
  getBookIscollect(params) {
    return axios.get(`${servrt2}/bookrack/user/checkcollect`, params);
  },
};
export default api;
