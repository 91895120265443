import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
const api = {
	//获取有效分类
	getEffectiveType(data) {
		return axios.get(`${servrt2}/task/type/list`,data);
	}
}

export default api