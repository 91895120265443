//志愿服务
export default [
   // 首页
   {
    path: "helpInPairs",
    name: "helpInPairs",
    component: () => import("@/views/helpInPairs/index.vue"),
  },
  {
    path: "helpDetail",
    name: "helpDetail",
    component: () => import("@/views/helpInPairs/detail.vue"),
  },
  {
    path: "helpProgress",
    name: "helpProgress",
    component: () => import("@/views/helpInPairs/progress.vue"),
  }
 
];
