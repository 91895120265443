// 调查问卷
export default [
    {
      path: "questionnaire",
      name: "questionnaire",
      component: () => import("@/views/questionnaire/index.vue"),
    },
    {
      path: "startexamine",
      name: "startexamine",
      component: () => import("@/views/questionnaire/start.vue"),
    },
  ];
  