export default [
  {
    path: "workPlan",
    name: "workPlan",
    component: () => import("@/views/workPlan/index.vue"),
  },
  {
    path: "workPlanDetail",
    name: "workPlanDetail",
    component: () => import("@/views/workPlan/detail.vue"),
  },
  {
    path: "workPlanForm",
    name: "workPlanForm",
    component: () => import("@/views/workPlan/form.vue"),
  },
];
