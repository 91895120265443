// 书记信箱模块
export default [
    // 首页
    {
        path: "email",
        name: "email",
        component: () => import("@/views/email/index.vue"),
    },
    {
        path: "emailDetail",
        name: "emailDetail",
        component: () => import("@/views/email/detail.vue"),
    },
    {
        path: "letter",
        name: "letter",
        component: () => import("@/views/email/letter.vue"),
    },
    {
        path: "reply",
        name: "reply",
        component: () => import("@/views/email/reply.vue"),
    },
];
