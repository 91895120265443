import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;
const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
const api = {
    //根据附件id获取附件名
    getFileNameById(params) {
        return axios.get( `${servrt1}/attachment/`+ params);
    },
    // 可报名活动
    getMeetingListActivity(params) {
        return axios.get(`${servrt2}/appactivity/applylist`, params);
    },
    //待开活动
    getReadyStartActivity(params) {
        return axios.get(`${servrt2}/appactivity/mylist`, params);
    },
    //获取活动详情
    getMeetingDetailActivity(params) {
        return axios.get(`${servrt2}/adminactivity/`+ params);
    },
    //填写心得
    writeExperienceActivity(params) {
        return axios.put(`${servrt2}/appactivity/addtips`, params);
    },
    //获取活动考勤状态
	getMeetingAttendActivity(params) {
		return axios.get(`${servrt2}/appactivity/getAttendanceModel`, params);
	},
    //获取该活动的考勤情况
    getMeetingAttendPersonActivity(params) {
		return axios.get(`${servrt2}/activity/attendance/getAttendanceCount`, params);
	},
    getMeetingAttendPersonListActivity(params) {
		return axios.get(`${servrt2}/activity/attendance/selectPage`, params);
	},
  
    //当前用户某活动的请假、补会数据
	getMeetingPunchMessageActivity(data) {
		return axios.get( `${servrt2}/appactivity/getApproval`,data);
	},
     //进行活动报名
	getSignUpGoActivity(data) {
		return axios.post(`${servrt2}/appactivity/`+ data);
	},
    //获取活动剩余报名人数
	getSignUpPerActivity(data) {
		return axios.get(`${servrt2}/appactivity/applynumber`, data);
	}
}

export default api