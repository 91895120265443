import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt1 = baseurl + "/" + process.env.VUE_APP_SERVER1;
const servrt2 = baseurl + "/" + process.env.VUE_APP_SERVER2;
const api = {
  // 根据ID查询党员
  getEvaluationUser(params) {
    return axios.get(`${servrt1}/user/${params.userId}`, {});
  },
  // 测评列表详情页
  getEvaluationExamInfo(params) {
    return axios.get(`${servrt2}/pcEvaluation/examInfo`, params);
  },
  // 查看记录
  getEvaluationRecords(params) {
    return axios.get(`${servrt2}/pcEvaluation/records`, params);
  },
  // 判断是否到测评时间
  getEvaluationIsStart(params) {
    return axios.get(`${servrt2}/pcEvaluation/isStart`, params);
  },
  // 分页查询自评人列表
  getEvaluationSelfPage(params) {
    return axios.get(`${servrt2}/pcEvaluation/selfPage`, params);
  },
  // 分页查询考核人列表
  getEvaluationSecretaryPage(params) {
    return axios.get(`${servrt2}/pcEvaluation/secretaryPage`, params);
  },
  // 分页查询考核人的人员列表
  getEvaluationSecretaryUserPage(params) {
    return axios.get(`${servrt2}/pcEvaluation/secretaryUserPage`, params);
  },
  // 提交测评结果
  getEvaluationSubmit(params) {
    return axios.put(
      `${servrt2}/pcEvaluation/submit?recordId=${params.recordId}`,
      params.submitDTOList
    );
  },
};
export default api;
