// 投票
export default [
    {
      path: "investigate",
      name: "investigate",
      component: () => import("@/views/investigate/index.vue"),
    },
    {
      path: "investigateDetail",
      name: "investigateDetail",
      component: () => import("@/views/investigate/detail.vue"),
    },
    {
      path: "investigateRank",
      name: "investigateRank",
      component: () => import("@/views/investigate/ranking.vue"),
    },
    {
      path: "investigateRankDetail",
      name: "investigateRankDetail",
      component: () => import("@/views/investigate/rankDetail.vue"),
    },
  ];
  