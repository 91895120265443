import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;
const api = {
    // 分页查询当前用户消息通知
    getMessageUserPage(data) {
        return axios.get(baseurl+`/system/message/page`, data);
    },
    //修改阅读状态
    getMessageUserState(data) {
        return axios.put( baseurl + `/system/message/state/${data}`);
    },
};
export default api;
