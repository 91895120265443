// 考试模块
export default [
  // 首页
  {
    path: "ExamIndex",
    name: "ExamIndex",
    component: () => import("@/views/exam/index.vue"),
  },
  // 开始考试之前详情
  {
    path: "ExamBeforeDetail",
    name: "ExamBeforeDetail",
    component: () => import("@/views/exam/beforeDetail.vue"),
  },
  // 开始考试及考试详情
  {
    path: "ExamDetail",
    name: "ExamDetail",
    component: () => import("@/views/exam/detail.vue"),
  },
  // 得分排行
  {
    path: "Ranking",
    name: "Ranking",
    component: () => import("@/views/exam/ranking.vue"),
  },
];
