import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;
const server3 = baseurl+'/'+process.env.VUE_APP_SERVER3;

const api={


	// 获取列表信息
	getProblemReportingList(params){
		return axios.get(`${server3}/appDifficulty`, params);
	},
	
	// 获取详情
	getProblemReportingDetails(params){
		return axios.get(`${server3}/appDifficulty/details`, params);
	},
	

	
	// 发起
	appDifficulty(params){
		return axios.post(`${server3}/appDifficulty`, params)
	},
	// 添加处理过程
	setInsertDeal(params){
		return axios.put(`${server3}/appDifficulty/insertDeal`, params)
	},
	// 填写监督
	insertSupervise(params){
        return axios.put(`${server3}/appDifficulty/insertSupervise`, params)
	},
	// 获取分类
	getTypeList(params){
        return axios.get(`${server3}/difficultyType`, params)
	},

	// 投票
	setVote(params){
        return axios.put(`${server3}/appDifficulty/vote?id=${params.id}&manner=${params.manner}`)
	},

	// 完成
	finishProblemReporting(params){
		return axios.put(`${server3}/appDifficulty/changeState?id=${params}`)
	}
}

export default api