//用户信息
const state = {
  keepViews:[] //personal页面需要缓存得得路由
};
const getters = {
  getkeepViews: () => {
    return state.keepViews;
  },
};
const mutations = {
  setMuKeepView(state, value) {
    state.keepViews=value
  },
};
const actions = {
  //这样直接存储其实并不好
  setKeepView({ commit }, value) {
    commit("setMuKeepView", value);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
