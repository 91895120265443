// 封装请求方法
import axios from "./axios";

const instance = axios();
interface IOptions {
  url: string;
  params?: unknown;
  headers?: unknown;
  data?: unknown;
}
export default {
  get(url: string, params?: unknown, headers?: unknown) {
    const options: IOptions = { url: "" };
    if (params) {
      options.params = params;
    }
    if (headers) {
      options.headers = headers;
    }
    return instance.get(url, options);
  },
  post(url: string, data?: unknown, headers?: unknown, params?: unknown) {
    const options: IOptions = { url: "" };
    if (headers) {
      options.headers = headers;
    }
    if (params) {
      options.params = params;
    }
    return instance.post(url, data, options);
  },
  put(url: string, params?: unknown, headers?: unknown) {
    const options: IOptions = { url: "" };
    if (headers) {
      options.headers = headers;
    }
    return instance.put(url, params, options);
  },
  delete(url: string, params?: unknown, headers?: unknown) {
    const options: IOptions = { url: "" };
    if (params) {
      options.params = params;
    }
    if (headers) {
      options.headers = headers;
    }
    return instance.delete(url, options);
  },
};


