import { createRouter, RouteRecordRaw, createWebHashHistory } from "vue-router";
import integral from "./module/integral";
import information from "./module/information";
import workPlan from "./module/workPlan";
import exam from "./module/exam";
import thought from "./module/thought";
import learning from "./module/learningRecord";
import notice from "./module/notice";
import meeting from "./module/meeting";
import train from "./module/train";
import activity from "./module/activity";
import email from "./module/email";
import questionnaire from "./module/questionnaire";
import dataAnalysis from "./module/dataAnalysis";
import investigate from "./module/investigate";
import annualCheck from "./module/annualCheck"; 
import democracy from "./module/democracy"; 
import helpInPairs from "./module/helpInPairs"; 
import mission from "./module/mission";  
import portrait from "./module/portrait";  
import payMoney from "./module/payMoney"; 
import bookCase from "./module/bookCase"; 
import problemReporting from "./module/problemReporting"; 
import wishCard from "./module/wishCard"; 
import todoMessage from "./module/todoMessage";
import onlineStudy from "./module/onlineStudy";
import field from "./module/field"; 
const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/loginChange",
    name: "loginChange",
    component: () => import("../views/loginChange.vue"),
  },
  {
    path: "/dataScreens",
    name: "dataScreens",
    component: () => import("@/views/dataScreen/index.vue"),
  },
  //TODO
  {
    path: "/greenScreens",
    name: "greenScreens",
    component: () => import("@/views/dataScreen/greenScreen.vue"),
  },
  {
    path: "/redScreens",
    name: "redScreens",
    component: () => import("@/views/dataScreen/redScreen.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/home.vue"),
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/index/index.vue"),
      },
      {
        path: "/personal",
        name: "personal",
        component: () => import("../views/index/personal.vue"),
        redirect: "/personal/TodoMessageIndex",
        children: [
          {
            path: "reminder",
            name: "reminder",
            component: () => import("../views/reminder/index.vue"),
          },
          ...information,
          ...workPlan,
          ...exam,
          ...thought,
          ...notice,
          ...meeting,
          ...mission,
          ...train,
          ...activity,
          ...learning,
          ...email,
          ...questionnaire,
          ...dataAnalysis,
          ...investigate,
          ...annualCheck,
          ...democracy,
          ...payMoney,
          ...integral,
          ...portrait,
          // ...annualCheck,
          ...todoMessage,
          ...bookCase,
          ...helpInPairs,
          ...problemReporting,
          ...wishCard,
          ...onlineStudy,
          ...field,
        ],
      },
      {
        path: "/details",
        name: "details",
        component: () => import("@/views/details/index.vue"),
      },
      {
        path: "/detailsOrganize",
        name: "detailsOrganize",
        component: () => import("@/views/details/indexOrganize.vue"),
      },
      {
        path: "/multiColumn",
        name: "multiColumn",
        component: () => import("@/views/multiColumn/index.vue"),
      },
      {
        path: "/organization",
        name: "organization",
        component: () => import("@/views/organization/index.vue"),
      },
    ],

    
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    //跳转页面返回顶部数组(页面名字)
    const routerArr = ["details","FieldAppointment","helpProgress","wishCardIndex","learning",
    "BookCase","investigate","email","Democracy","payMoney","helpInPairs",
    "OnlineStudy","FieldIndex","integral"]
    if (savedPosition) {
      return savedPosition
    } else if(routerArr.indexOf(String(to.name))!=-1){
      return { top: 0 }
    }
  },
});
export default router;
