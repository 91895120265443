import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
const api = {
  //推送给我的
  workselectList(params) {
    return axios.get(`${servrt2}/workSummaryRecord/pcList`, params);
  },
  //私人的工作计划列表
  workselectSelfList(params) {
    return axios.get(
      `${servrt2}/workSummaryRecord/pcSelfList`,
      params
    );
  },
  // //get 示例,参数是对象
  // gettests(params: string) {
  //   return axios.get(
  //     // baseurl + "/note/downloadFile?name="+params,
  //     `https://mock.mengxuegu.com/mock/6066b7e7e34b2e50a355c6bf/partybuilding/moke`,
  //     params
  //   );
  // },
  // //post 示例
  // addNoteType(params: unknown) {
  //   return axios.post(
  //     `https://mock.mengxuegu.com/mock/6066b7e7e34b2e50a355c6bf/partybuilding/moke`,
  //     params
  //   );
  // },
  //推送给我的--查看详情
  workselfDetail(params) {
    return axios.get(`${servrt2}/workSummaryRecord/pcDetail`, params);
  },
  //私人的--查看详情
  // workselfDetail(params) {
  //   return axios.get(`${servrt2}/workSummaryRecord/pcDetail`, params);
  // },
  //添加私人工作计划和总结
  workselfAdd(params) {
    return axios.post(`${servrt2}/workSummaryRecord/selfAdd`, params);
  },

  //删除
  workselfDel(params) {
    return axios.delete(
      `${servrt2}/workSummaryRecord/delSelf`,
      params
    );
  },
  //私人-编辑
  workselfEdit(params) {
    return axios.put(`${servrt2}/workSummaryRecord/pcAdd`, params);
  }
};
export default api;
