// 年度考核模块
export default [
  // 首页
  {
    path: "AnnualCheck",
    name: "AnnualCheck",
    component: () => import("@/views/annualCheck/index.vue"),
  },
  // 年度考核之前的详情
  {
    path: "AnnualBeforeDetail",
    name: "AnnualBeforeDetail",
    component: () => import("@/views/annualCheck/beforeDetail.vue"),
  },
  // 年度考核详情
  {
    path: "AnnualDetail",
    name: "AnnualDetail",
    component: () => import("@/views/annualCheck/detail.vue"),
  },
];
