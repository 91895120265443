// 场地预约模块
export default [
    // 首页
    {
      path: "FieldIndex",
      name: "FieldIndex",
      component: () => import("@/views/field/index.vue"),
    },
    {
      path: "MyFieldIndex",
      name: "MyFieldIndex",
      component: () => import("@/views/field/myfield.vue"),
    },
    {
      path: "FieldDetail",
      name: "FieldDetail",
      component: () => import("@/views/field/detail.vue"),
    },
    {
      path: "FieldAppointment",
      name: "FieldAppointment",
      component: () => import("@/views/field/appointment.vue"),
    },
    
  ];