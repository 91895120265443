// 消息待办模块
export default [
  // 首页
  {
    path: "TodoMessageIndex",
    name: "TodoMessageIndex",
    component: () => import("@/views/todoMessage/index.vue"),
  },
  // 开始考试之前详情
  // {
  //   path: "TodoMessageDetail",
  //   name: "TodoMessageDetail",
  //   component: () => import("@/views/todoMessage/beforeDetail.vue"),
  // },
  // 开始考试及考试详情
  // {
  //   path: "TodoMessageDetail",
  //   name: "TodoMessageDetail",
  //   component: () => import("@/views/todoMessage/detail.vue"),
  // },
];
