import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt1 = baseurl + '/' + process.env.VUE_APP_SERVER1;
const api = {
    // 获取个人资料
    getInformation(params) {
        return axios.get(`${servrt1}/user`, params);
    },
    // 获取扩展字段
    getExpand(params) {
        return axios.get(`${servrt1}/user/expand/page/`, params);
    },
    // 获取扩展字段信息
    getExpandContent(params) {
        return axios.get(`${servrt1}/user/expand/${params}`);
    },
    // 根据id编辑回显
    getUser(params) {
        return axios.get(`${servrt1}/user/${params}`);
    },
    // 编辑
    editPerson(params) {
        return axios.put(`${servrt1}/user`, params);
    },
    //上传头像
    uploadHead(params) {
        return axios.put(`${servrt1}/user/uploadHead`, params);
    },
    // 民族
    nationList(params) {
        return axios.get(`${servrt1}/nation`, params);
    },
    // 民族
    educationList(params) {
        return axios.get(`${servrt1}/education`, params);
    },
    // 审批
    approveListw(params) {
        return axios.get(baseurl + `/system/sysApproveConfig/approveList`, params);
    },
    // 审批
    approveUserListw(params) {
        return axios.get(baseurl + `/system/sysApproveConfig/approveUserList`, params);
    },
}

export default api