// 年度考核模块
export default [
    // 首页
    {
        path: "BookCase",
        name: "BookCase",
        component: () => import("@/views/bookCase/index.vue"),
    },  
    {
        path: "myBookCase",
        name: "myBookCase",
        component: () => import("@/views/bookCase/myBookCase.vue"),
    },
    {
        path: "myBookCaseDetail",
        name: "myBookCaseDetail",
        component: () => import("@/views/bookCase/detail.vue"),
    },
    {
        path: "readBook",
        name: "readBook",
        component: () => import("@/views/bookCase/readBook.vue"),
    },
  ];
  