export default [
    // 民主评议
    {
      path: "democracy",
      name: "Democracy",
      component: () => import("@/views/democracy/index.vue"),
    },
    {
      path: "answerQuestions",
      name: "AnswerQuestions",
      component: () => import("@/views/democracy/answerQuestions.vue"),
    },
    {
      path: "historyRecord",
      name: "HistoryRecord",
      component: () => import("@/views/democracy/historyRecord.vue"),
    },
    {
      path: "evaluator",
      name: "Evaluator",
      component: () => import("@/views/democracy/evaluator.vue"),
    },
    {
      path: "reviewee",
      name: "Reviewee",
      component: () => import("@/views/democracy/reviewee.vue"),
    },
  ];
  