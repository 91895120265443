import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
const api = {
  //书信列表
  emailList(params) {
    return axios.get(`${servrt2}/mailbox/my-list`,params);
  },
  //获取详情
  emailDetail(params) {
    return axios.get(`${servrt2}/mailbox/${params}`);
  },
  //写信
  writeEmail(params) {
    return axios.post(`${servrt2}/mailbox`,params);
  },
  //编辑
  editEmail(params) {
    return axios.put(`${servrt2}/mailbox/edit`,params);
  },
   //删除
   delEmail(params) {
    return axios.delete(`${servrt2}/app-mailbox/${params}`);
  },
  //获取书记id
  isSecretary(params) {
    return axios.get(`${servrt2}/mailbox/querySecretary`,params);
  },
  //回复接口
  replyEmail(params) {
    return axios.post(`${servrt2}/mailbox-receive`,params);
  },
  //修改状态
  emailStatus(params) {
    return axios.put(`${servrt2}/mailbox/status/${params}`);
  },
  //回复详情
  replyDetail(params) {
    return axios.get(`${servrt2}/mailbox-receive/${params}`);
  },
}
export default api;