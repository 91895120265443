//后台配置的设置信息
const state = {
  //是否演示情况
  demonstration: true,
};
const getters = {
  getDemonstration: () => {
    return state.demonstration;
  },
};
const mutations = {
  SET_DEMONSTRATION(state, value) {
    state.demonstration = value;
  },
};
const actions = {
  setDemonstration({ commit }, value) {
    commit("SET_DEMONSTRATION", value);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
