import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const api = {
    // 栏目列表
    getcolumnList(params) {
        return axios.get(`${servrt1}/studyOnlineColumn/columnList`, params);
    },
    //文章列表
    getarticleList(params) {
        return axios.get(`${servrt1}/appStudyOnline`, params);
    },
    //文章列表
    getnewsDetail(params) {
        return axios.get(`${servrt1}/appStudyOnline/select/${params}`);
    },
    //是否收藏
    getCollect(params) {
        return axios.get(`${servrt1}/collect`,params);
    },
    //收藏
    addCollect(params) {
        return axios.post(`${servrt1}/collect`,params);
    },
    //取消收藏
    studyDeleteCollect(params) {
        return axios.delete(`${servrt1}/collect/${params}`);
    },
    //我的收藏列表
    getLrbList(params) {
        return axios.get(`${servrt1}/collect/page`,params);
    },
    //排行榜学分
    getperiodRank(params) {
        return axios.get(`${servrt1}/appStudyOnline/periodRank`,params);
    },
    //排行榜学分
    getcreditRank(params) {
        return axios.get(`${servrt1}/appStudyOnline/creditRank`,params);
    },
    //生成学习记录
    addRecord(params) {
        return axios.post(`${servrt1}/appStudyOnline`,params);
    },
	//修改学习记录
    updateRecord(params) {
        return axios.put(`${servrt1}/appStudyOnline`,params);
    },
}

export default api