import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const api = {
  //上传返回地址
  upload(params) {
    return axios.post(`${servrt1}/file/upload`, params, {
      "Content-Type": "multipart/form-data;",
      Accept: "*/*",
    });
  },
  //上传返回id
  attachUpload(params) {
    return axios.post(`${servrt1}/attachment/upload`, params, {
      "Content-Type": "multipart/form-data;",
      Accept: "*/*",
    });
  },

  //登录
  webLogin(params) {
    return axios.post(`${servrt1}/auth/web/login`, params);
  },
  //退出登录
  logout() {
    return axios.delete(`${servrt1}/auth/logout`);
  },
  //验证码
  verificationCode(params) {
    return axios.get(`${servrt1}/auth/kaptcha/create`, params);
  },
  //验证码验证
  verideploy(params) {
    return axios.get(`${servrt1}/auth/kaptcha/state`, params);
  },
  //格式化token
  formatToken() {
    return axios.post(`${servrt1}/auth/token/format`);
  },
  //登录状态校验
  tokenAlive() {
    return axios.get(`${servrt1}/auth/token/alive`);
  },
  //刷新token
  refreshToken() {
    return axios.post(`${servrt1}/auth/refresh`);
  },
  // 附件获取
  getAttachment(params) {
    return axios.get(`${servrt1}/attachment/`, params);
  },
  //获取组织
  getOrgTree() {
    return axios.get(`${servrt1}/org/home/tree`);
  },
  //获取后台配置
  getDemonstration(params) {
    return axios.get(`${servrt1}/config/getByName`, params);
  },
  //获取演示账号
  demonstrateLogin(params) {
    return axios.get(`${servrt1}/auth/demo/user`, params);
  },
 
}
export default api;
