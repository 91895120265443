import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt1 = baseurl + "/" + process.env.VUE_APP_SERVER1;
const api = {
  // 新闻文章banner
  articleList(params) {
    return axios.get(`${servrt1}/home/journalism/page`, params);
  },
  // 栏目列表
  columnList(params) {
    return axios.get(`${servrt1}/home/journalism/column/list`, params);
  },

  // 新闻列表
  newsList(params) {
    return axios.get(`${servrt1}/home/journalism/page`, params);
  },
  // 处分
  punishment(params) {
    return axios.get(`${servrt1}/home/comprehensive/page`, params);
  },
  // 规章制度
  system(params) {
    return axios.get(`${servrt1}/home/comprehensive/page`, params);
  },
  // 两学一做
  learn(params) {
    return axios.get(`${servrt1}/home/comprehensive/page`, params);
  },
  // 三会一课
  lessonList(params) {
    return axios.get(`${servrt1}/home/comprehensive/page`, params);
  },
  //首页需要下载列表
  downloadDetails(params) {
    return axios.get(`${servrt1}/home/comprehensive/page`, params);
  },
  //获取模块开启情况
  getSwitchList(params) {
    return axios.get(`${servrt1}/sysModuleSwitch/list`);
  },
};
export default api;
