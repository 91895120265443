import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt2 = baseurl + "/" + 'community';
const api = {
  //列表
  FieldList(params) {
    return axios.get(`${servrt2}/fieldApp/fieldList`, params);
  },
  FieldPcList(params) {
    return axios.get(`${servrt2}/fieldPc/fieldList`, params);
  },
  
  //详情
  FieldDetail(params) {
    return axios.get(`${servrt2}/fieldApp/getDetails?fieldId=`+ params);
  },
  //我的列表
  FieldMyList(params) {
    return axios.get(`${servrt2}/record/myPage`, params);
  },
  //新增预约场地
  addField(params) {
    return axios.post(`${servrt2}/fieldApp/add`, params);
	},
  //获取单个场地预约时间
	getFieldTime(params) {
    return axios.get(`${servrt2}/field/findAlready`, params);
	},
  //获取禁用时间
	getFieldDisTime(params) {
    return axios.get(`${servrt2}/field/findNofield`, params);
	},
  
  //取消预约
	Fieldcancel(params) {
    return axios.delete(`${servrt2}/fieldApp/cancel?recordId=${params}`);
	},

  //查看地图状态
  watchMap(params) {
    return axios.get(`/api/system/config/map`,params);
  }
};
export default api;