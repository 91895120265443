import router from "@/router";
import axios from "axios";
// import { Toast } from "vant";
import { ElMessage } from "element-plus";
import Cookies from "js-cookie";
import { deleteAllCookies } from "@/hooks/auth";
// import { resolveComponent } from "vue";
// import { useRouter } from "vue-router";
let isRefreshing = false; //标记正在刷新的token,防止多次杀心token
let requests: any = []; //存储等待重发的数组（同时发起多个请求的处理）
// 刷新 access_token 的接口
const baseurl = process.env.VUE_APP_BASE_API;
const server1 = process.env.VUE_APP_SERVER1;
// 创建 axios 实例
const http = axios.create({
  timeout: 60000,
});
// 设置 post、put 默认 Content-Type
http.defaults.headers.post["Content-Type"] = "application/json";
http.defaults.headers.put["Content-Type"] = "application/json";
// 添加请求拦截器
http.interceptors.request.use(
  (config: { method: string; url: string | string[] }) => {
    if (config.method === "post" || config.method === "put") {
      // post、put 提交时，将对象转换为string, 为处理Java后台解析问题
    } else if (config.method === "get") {
      // 给GET 请求后追加时间戳， 解决IE GET 请求缓存问题 && browser.isIE
      const symbol = config.url.indexOf("?") >= 0 ? "&" : "?";
      config.url += symbol + "_=" + Date.now();
    }
    // 请求发送前进行处理
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);
// 添加响应拦截器
http.interceptors.response.use(
  async (response: { data; status; config }) => {
    const { data, status, config } = response;
    if (status === 200) {
      //暂时模拟
      if (data.code == 401) {
        ElMessage.error("请先进行登录");
        return data;
      } else if (
        data.code == 4001 &&
        !config.url.includes("auth/web/refresh")
      ) {
        
        //刷新token接口不走此拦截
        if (!isRefreshing) {
          //正在刷新
          isRefreshing = true;
          //拿到长token
          const option = {
            method: "POST",
            headers: {
              // "Refresh-Token": sessionStorage.getItem("refreshToken"),
              "Refresh-Token": Cookies.get("refreshToken"),
            },
            url: `${baseurl}/${server1}/auth/web/refresh`,
          };
          return new Promise((res) => {
            http(option)
              .then(function (response) {
                if (response.code == 0) {
                  //重新赋值短token
                  const token = response.data.access_token;
                  config.headers.Authorization = token;
                  http.defaults.headers.Authorization = token;
                  //重新存储短toke 与长token
                  // sessionStorage.setItem("token", response.data.access_token);
                  // sessionStorage.setItem(
                  //   "refreshToken",
                  //   response.data.refresh_token
                  // );
                  Cookies.set("token", response.data.access_token);
                  Cookies.set(
                    "refreshToken",
                    response.data.refresh_token
                  );
                  
                  //token刷新后将数组的方法重新执行
                  requests.forEach((cb) => cb(token));
                  requests = []; // 重新请求完清空
                  res(http(config));
                } else {
                  sessionStorage.clear();
                  deleteAllCookies()
                  ElMessage.error("抱歉，您的登录状态已失效，请重新登录！");
                  setTimeout(() => {
                    router.push("/");
                  }, 1000);
                }
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        } else {
          return new Promise((resolve) => {
            // 用函数形式将 resolve 存入，等待刷新后再执行
            requests.push((token) => {
              config.headers.Authorization = token;
              resolve(http(config));
            });
          });
          // return Promise.reject(data);
        }
      } else if (data.code == 500) {
        if (config.url.indexOf(`${server1}/auth/kaptcha`) == -1) {
          ElMessage.error(data.msg);
        }
        return data;
      } else {
        //code等于0代表成功
        return data;
      }
    } else {
      // Toast(data.msg)
      return Promise.reject(data);
    }
  },
  (error: { response: { status; statusText; data } }) => {
    let info = {};
    const { status, statusText, data } = error.response;
    if (!error.response) {
      info = {
        code: 5000,
        msg: "Network Error",
      };
    } else {
      // 此处整理错误信息格式
      info = {
        code: status,
        data: data,
        msg: statusText,
      };
    }
    // Notify(statusText);
    return Promise.reject(info);
  }
);
export default function () {
  return http;
}
