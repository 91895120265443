import { createStore } from 'vuex'

const files = require.context("./module", true, /.ts$/);
const storeArray = {};
files.keys().forEach((v) => {
  const name =v.substring(2,v.length-3)
  storeArray[name]=files(v).default
  //读取文件
});

export default createStore({modules:storeArray})
