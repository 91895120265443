// 党员画像
export default [
    {
      path: "problemReporting",
      name: "problemReporting",
      component: () => import("@/views/problemReporting/index.vue"),
    },
    {
      path: "problemDetails",
      name: "problemDetails",
      component: () => import("@/views/problemReporting/details.vue"),
    },
    {
      path: "problemProgress",
      name: "problemProgress",
      component: () => import("@/views/problemReporting/progress.vue"),
    },
    // 新增页面
    {
      path: "problemAdd",
      name: "problemAdd",
      component: () => import("@/views/problemReporting/add.vue"),
    }


    
  
  ];