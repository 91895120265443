// 积分中心模块
export default [
    // 首页
    {
      path: "integral",
      name: "integral",
      component: () => import("@/views/integral/index.vue"),
    },
    {
      path: "integralRank",
      name: "integralRank",
      component: () => import("@/views/integral/ranking.vue"),
    },
  ];