
const state = {
    listlength:0,
    aWebSocket:null
  }
  
  const mutations = {
   setbeage:(state,data)=>{
       if(data>=0){
          state.listlength = data
       }else{
          state.listlength =0
       }
      
   },
   setNewWebsockety:(state,data)=>{
    state.aWebSocket = new WebSocket(
      ` ${ process.env.VUE_APP_PCHREFWS}/websocket/pc/api/pushMessage/${data.data}`
     );
     state. aWebSocket.onopen = function (event) {
      if (state.aWebSocket.readyState === 1) {
        state. aWebSocket.send(data.token)
      console.log("链接打开12", data.token);
    }
      
    };
   }
  }
  
  const actions = {
      setactionbeage(conent ,data:number) {
        conent.commit('setbeage',data)
    },
    setNewWebsockety(conent ,data) {
      conent.commit('setNewWebsockety',data)
  },
   
  }
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
  