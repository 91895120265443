import axios from "../../index";
const api = {
  //get 示例,参数是对象
  gettests(params: string) {
    return axios.get(
      // baseurl + "/note/downloadFile?name="+params,
      `https://mock.mengxuegu.com/mock/6066b7e7e34b2e50a355c6bf/partybuilding/moke`,
      params
    );
  },
  //post 示例
  addNoteType(params: unknown) {
    return axios.post(
      `https://mock.mengxuegu.com/mock/6066b7e7e34b2e50a355c6bf/partybuilding/moke`,
      params
    );
  },
};
export default api;
