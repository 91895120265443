import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;
const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
const api = {
    //根据附件id获取附件名
    getFileNameById(params) {
        return axios.get(`${servrt1}/attachment/`+ params);
    },
    // 可报名会议
    getMeetingList(params) {
        return axios.get(`${servrt2}/appmeeting/applylist`, params);
    },
    //待开会议
    getReadyStart(params) {
        return axios.get(`${servrt2}/appmeeting/mylist`, params);
    },
    //获取会议详情
    getMeetingDetail(params) {
        return axios.get(`${servrt2}/adminmeeting/`+ params);
    },
    //填写心得
    writeExperience(params) {
        return axios.put(`${servrt2}/appmeeting/addtips`, params);
    },
    //获取会议考勤状态
	getMeetingAttend(params) {
		return axios.get(`${servrt2}/appmeeting/getAttendanceModel`, params);
	},
    //获取该会议的考勤情况
    getMeetingAttendPerson(params) {
		return axios.get(`${servrt2}/new-meeting-attendance/getAttendanceCount`, params);
	},
    getMeetingAttendPersonList(params) {
		return axios.get(`${servrt2}/new-meeting-attendance/selectPage`, params);
	},
  
    //当前用户某会议的请假、补会数据
	getMeetingPunchMessage(data) {
		return axios.get(`${servrt2}/appmeeting/getApproval`,data);
	},
    //进行会议报名
	getSignUpGo(data) {
		return axios.post(`${servrt2}/appmeeting/`+ data);
	},
    //获取会议剩余报名人数
	getSignUpPer(data) {
		return axios.get(`${servrt2}/appmeeting/applynumber`, data);
	},
}

export default api