import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const api = {
    // 获取通知列表
    getNoticeList(params) {
        return axios.get(`${servrt1}/notice/myList`, params);
    },

    // 获取通知详情
    getNoticeDetail(params) {
        return axios.get(`${servrt1}/notice/${params}`);
    }
}

export default api