import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const servrt2 = baseurl+'/'+process.env.VUE_APP_SERVER2;
const api = {
    //根据附件id获取附件名
   getFileNameById(params) {
       return axios.get(`${servrt1}/attachment/`+ params);
   },
    // 可报名培训
    getMeetingListTrain(params) {
        return axios.get(`${servrt2}/apptrain/applylist`, params);
    },
    //待开培训
    getReadyStartTrain(params) {
        return axios.get(`${servrt2}/apptrain/mylist`, params);
    },
    //获取培训详情
    getMeetingDetailTrain(params) {
        return axios.get(`${servrt2}/admintrain/`+ params);
    },
    //填写心得
    writeExperienceTrain(params) {
        return axios.put(`${servrt2}/apptrain/addtips`, params);
    },
    //获取培训考勤状态
	getMeetingAttendTrain(params) {
		return axios.get(`${servrt2}/apptrain/getAttendanceModel`, params);
	},
    //获取该培训的考勤情况
    getMeetingAttendPersonTrain(params) {
		return axios.get(`${servrt2}/train/attendance/getAttendanceCount`, params);
	},
    getMeetingAttendPersonListTrain(params) {
		return axios.get(`${servrt2}/train/attendance/selectPage`, params);
	},
    //当前用户某培训的请假、补会数据
	getMeetingPunchMessageTrain(data) {
		return axios.get(`${servrt2}/apptrain/getApproval`,data);
	},
    //进行培训报名
	getSignUpGoTrain(data) {
		return axios.post(`${servrt2}/apptrain/`+ data);
	},
    //获取培训剩余报名人数
	getSignUpPerTrain(data) {
		return axios.get(`${servrt2}/apptrain/applynumber`, data);
	}
}

export default api