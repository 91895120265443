export default [
    {
      path: "learning",
      name: "learning",
      component: () => import("@/views/learningRecord/index.vue"),
    },
    {
      path: "learningDetail",
      name: "learningDetail",
      component: () => import("@/views/learningRecord/detail.vue"),
    },
]