import axios from "@/api/index";
const baseurl = process.env.VUE_APP_BASE_API;

const servrt1 = baseurl+'/'+process.env.VUE_APP_SERVER1;
const api = {
  // 调用添加积分接口
  getChangeInfoTotal(params) {
    return axios.post(`${servrt1}/user/score`, params);
  },
  // 调用添加积分接口
  getChangeInfoList(params) {
    return axios.get(`${servrt1}/user/score/list`, params);
  },
};
export default api;
